<template>
  <FocusTrap>
    <div id="mycard" class="content">

      <!-- Horizontal cards -->
      <div class="mb-3 pt-2">
        <h6 class="mb-0 font-weight-semibold">
          Reminder Cards
        </h6>
        <span class="text-muted d-block">{{date_title}}</span>
      </div>

      <div class="row" >
        <div class="col-md-3" v-for="card in cards">
          <ReminderCard :remindercard = "card"/>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import ReminderCard from '../../../components/ReminderCard'
export default {
  name: 'ReminderCall',
  components: { ReminderCard },
  store,
  data(){
    return {
      working_date : moment(),
      date_title : '',
      cards : [],
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date;
  },
  mounted () {
    const self = this;

    try {
      self.$data.date_title = moment(self.$data.working_date).format('ll');

      let today = moment(new Date()).format('YYYY-MM-DD');

      // self.$store.state.user.doctors.forEach((doctor)=>{
      //   let reminderdar =  JSON.parse('{"id":0,"doctor":{"id":0,"name":"","city":"","qualification":"","last_visit":null},"product1":{"id":0,"name":""},"product2":{"id":0,"name":""},"product3":{"id":0,"name":""},"product4":{"id":0,"name":""}}');
      //   reminderdar.doctor = doctor;
      //   self.$data.cards.push(reminderdar);
      // });

      self.loadDayPlan();

    }catch (e) {
      alert(e);
    }
  },
  methods:{
    loadDayPlan(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        // alert(`${process.env.VUE_APP_ROOT_API}api/tourplanday/emp/${self.$store.state.user.id}/${self.$data.working_date}`);
        self.$data.today_areas = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/tourplanday/emp/${self.$store.state.user.id}/${self.$data.working_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {

              if ('areas1' in resp.data) {
                resp.data.areas1.forEach((a) => {
                  self.$data.today_areas.push(a.area_id);
                });
              }

              if ('areas2' in resp.data) {
                resp.data.areas2.forEach((a) => {
                  self.$data.today_areas.push(a.area_id);
                });
              }

              if ('areas' in resp.data) {
                resp.data.areas.forEach((a) => {
                  self.$data.today_areas.push(a.area_id);
                });
              }

              self.loadReportingDoctors();
            }

          } else {
            swal({ title: "Oops!", text: "Day Planning not found", type: "error" });
            // swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });
      }catch (e) {
        alert(e);
      }

    },
    loadReportingDoctors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let areas =  JSON.stringify(self.$data.today_areas);

      areas = areas.replace('[','');
      areas = areas.replace(']','');

      self.$data.reportings = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/doctors/active/area/${areas}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            resp.data.forEach((doctor)=>{
              let rep = {"id":0,"date":"","doctor":{"id":0,"name":"","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]};
              rep.doctor = doctor;
              self.$data.reportings.push(rep);
            });
          }
        } else {
          // swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          swal({ title: "Oops!", text: "Day Planning not found", type: "error" });
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>

</style>
