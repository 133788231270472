<template>
  <div >

    <!-- Linked list group -->
    <div class="card">

      <div class="card-body">
        <h6 class="mb-0">{{card.doctor.name}}</h6>
        <span class="text-muted">{{card.doctor.qualification}}</span>
        <p class="card-text">{{card.doctor.area.name}}</p>

        <div class="row">
          <div class="col-sm">
            <label class="form-control font-weight-semibold">Intervel</label>
          </div>
          <div class="col-sm">
            <Select class="form-control">
              <option value="1">Once in Month</option>
              <option value="2">Twice in Month</option>
              <option value="4">Weekly</option>
            </Select>
          </div>
        </div>

        <div class="row">

          <div class="form-check form-check-inline">
            <input class="form-check-input primary" type="checkbox" value="1">
            <label class="form-check-label">Su</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="chkDay2" value="2">
            <label class="form-check-label" for="chkDay2">Mo</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="chkDay3" value="3">
            <label class="form-check-label" for="chkDay3">Tu</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="chkDay4" value="4">
            <label class="form-check-label" for="chkDay4">We</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="chkDay5" value="5">
            <label class="form-check-label" for="chkDay5">Th</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="chkDay6" value="6">
            <label class="form-check-label" for="chkDay6">Fr</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="chkDay7" value="7">
            <label class="form-check-label" for="chkDay7">Sa</label>
          </div>

        </div>
      </div>

      <ul class="list-group border-x-0 rounded-0 border-top">

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

        <div href="#" class="list-group-item list-group-item-action">
          <select class="form-control">
            <option v-for="item in $store.state.user.products"> {{item.name}}</option>
          </select>
        </div>

      </ul>

      <div class="card-footer d-flex justify-content-between border-top">
        <span class="text-muted">{{ last_visit }}</span>
        <button class="btn btn-outline-success">Update</button>
      </div>
    </div>
    <!-- /linked list group -->

  </div>
</template>

<script>
export default {
  name: 'ReminderCard',
  props:{
    remindercard: JSON.parse('{"id":0,"doctor":{"id":0,"name":"","city":"","qualification":"","last_visit":null},"product1":{"id":0,"name":""},"product2":{"id":0,"name":""},"product3":{"id":0,"name":""},"product4":{"id":0,"name":""}}'),
  },
  data(){
    return {
      card: JSON.parse('{"id":0,"doctor":{"id":0,"name":"","city":"","qualification":"","last_visit":null},"product1":{"id":0,"name":""},"product2":{"id":0,"name":""},"product3":{"id":0,"name":""},"product4":{"id":0,"name":""}}'),
      last_visit : 'last_visit'
    }
  },
  created () {
  },
  beforeMount () {
    this.card = this.remindercard;
    // if(this.card.doctor.id === 35123){
    this.$data.last_visit = moment(this.$data.card.doctor.last_visit).fromNow();
    // }
  },
  mounted () {
  },
  methods:{
  }
}
</script>

<style scoped>

</style>
